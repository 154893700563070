@import // "bootstrap/scss/bootstrap",
        "base",
        "customisations/customisations",
        "tutorial_guide";

body {
    font-family: 'Poppins', sans-serif;
    a {
        color: $link_color;
        text-decoration: none;
        &:hover {
            color: $link_color;
        }
    }

    img {
        max-width: 100%;
    }

    button {
        &.brand_default, &.btn.brand_default {
            @include DefaultButton();
        }

        &.brand_light, &.btn.brand_light {
            @include LightButton();
        }

        &.brand_thin, &.btn.brand_thin {
            @include ThinButton();
        }

        &.brand_danger, &.btn.brand_danger {
            @include DangerButton();
        }

        &.brand_secondary, &.btn.brand_secondary {
            @include SecondaryButton();
        }
    }

    label {
        &.brand_light {
            @include LightLabel();
        }
    }

    .text_brand {
        color: $nav_color;

        &:hover {
            color: $nav_color;
        }
    }

    .text_brand_alternative {
        color: $nav_alternative_color;
    }
}

i.material-icons {
    font-size: unset;
}

#page_container {
    .view_nav {
        position: relative;

        .row {
            &.section_wrapper {
                @include DefaultShadow();
                background: #fff;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 15px;
                padding-top: 15px;
                padding-bottom: 15px;

                &:last-of-type {
                    margin-bottom: 0px;
                }
            }

            div[class^="col"] {
                .box_wrapper {
                    @include DefaultShadow();
                    background: #fff;
                }
            }
        }
    }
}

.steps_container {
    width: 100%;
}

i.dialogflow_icon {
    background-size: contain;
    display: inline-block;
    width: 16px;
    height: 20px;
    vertical-align: middle;

    &_es {
        background-image: url('#{$ASSETS_PATH}/dialogflow_icon.png');
    }

    &_cx {
        background-image: url('#{$ASSETS_PATH}/dialogflow_cx_icon.png');
    }

}

i.tellya_icon {
    background: url('#{$ASSETS_PATH}/tellya_symbol.png') center center no-repeat;
    background-size: contain;
    display: inline-block;
    width: 45px;
    height: 40px;
    vertical-align: middle;
}

.cdk-drag-preview {
    z-index: 1051 !important;
}